import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { routes } from './routes'

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

let mode ='history';
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === undefined) {
  mode = 'hash';
}

const router = new VueRouter({
  routes,
  mode
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
