<template>
  <main class="main">
    <section>
      <p>We at Sora are devoted to the craft of producing images that capture the beauty of architecture. They are created to be in harmony with the existing surroundings, without employing tricks, and ensuring that the architecture takes center stage.</p>
    </section>
    <section class="">
      <figure>
        <img
                :data-src="`https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/v1578673283/raw/paris-from-top_ax8ifu.jpg`"
                :data-srcset="`https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_360/v1578673283/raw/paris-from-top_ax8ifu.jpg 320w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_520/v1578673283/raw/paris-from-top_ax8ifu.jpg 480w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_800/v1578673283/raw/paris-from-top_ax8ifu.jpg 800w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/v1578673283/raw/paris-from-top_ax8ifu.jpg 1000w`"
                sizes="(max-width: 320px) 280px,
             (max-width: 480px) 440px,
             (max-width: 800px) 768px,
             1000px"
                alt="paris-from-above"
                class="lazyload"
        />
      </figure>
    </section>
  </main>
</template>

<script>
export default {
  name: 'about2',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
