<template>
    <main class="main">
        <section>
            <ul>
                <li>Viktor Fretyán</li>
                <li>Tamás Medve</li>
                <li>Michail Kafasis</li>
            </ul>
            <address>
                <a href="mailto:info@sora-images.com">info@sora-images.com</a>
                <br/>
                <a href="tel:+6494461709">+33 7 62 01 23 24</a>
            </address>
            <a href="https://www.instagram.com/sora.images/" target="_blank">
                <svg
                        class="social-icon"
                        height="50"
                        viewBox="0 0 24 24"
                        width="50"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <defs/>
                    <path
                            d="M8 3a5 5 0 00-5 5v8a5 5 0 005 5h8a5 5 0 005-5V8a5 5 0 00-5-5H8zm10 2a1 1 0 110 2 1 1 0 010-2zm-6 2a5 5 0 11-.001 10.001A5 5 0 0112 7zm0 2a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3z"
                    />
                </svg>
            </a>
        </section>
    </main>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Contact us',
            meta: [
                {hid: 'description', name: 'description', content: ''}
            ]
        }
    };
</script>

<style lang="scss">
    address {
        font-style: inherit;
        padding-top: 2rem;
    }

    a {
        text-decoration: none;
        color: inherit;
        font-family: inherit;
        font-style: inherit;
    }

    .social-icon {
        width: 1.5rem;

        &:hover,
        &:focus,
        &.is-active {
            fill: #4a4a4a;
        }
    }
</style>
