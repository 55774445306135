<template>
  <main class="work-section">
    <section class="gallery" v-for="(imageBlock, index) in imageArray" :key="index" v-cloak>
      <figure
        class="gallery-item"
        :style="getRatio(image.width, image.height, imageBlock)"
        v-for="image in imageBlock"
        :key="image.id"
        @click="initCarousel(image.id)"
      >
        <img
          :data-src="getSrc(image.src)"
          :data-srcset="getSrcSet(image.src)"
          sizes="(max-width: 320px) 280px,
           (max-width: 480px) 440px,
           (max-width: 800px) 768px,
           1000px"
          :alt="image.alt"
          class="lazyload"
        />
      </figure>
    </section>
    <section class="carousel" v-if="carousel">
      <div @click.stop="carouselPrevious" class="carousel-previous">
        <span class="sr-only">Previous</span>
      </div>
      <div @click.stop="carouselNext" class="carousel-next">
        <span class="sr-only">Next</span>
      </div>
      <transition-group tag="div" name="fade" class="carousel-container">
        <figure
          v-show="activeCarouselItem === index"
          class="carousel-item"
          v-for="(item, index) in carouselItems"
          :key="item.id"
          @click.stop="destroyCarousel"
        >
          <img
            :src="getSrc(item.src)"
            :srcset="getSrcSet(item.src)"
            :alt="item.alt"
            :key="`${item.id}-image`"
            @click.stop="carouselNext"
          />
          <figcaption>{{item.alt}}</figcaption>
        </figure>
      </transition-group>
    </section>
    <div v-show="!carousel" @click.stop="scrollToTop" class="scroll-to-top">
      <span class="sr-only">Scroll to the top</span>
    </div>
  </main>
</template>

<script>
import axios from "@/services/axios";
import "lazysizes";

export default {
  metaInfo: {
    htmlAttrs: {
      class: "overflow"
    },
    title: "Work | Sora",
    meta: [{ hid: "description", name: "description", content: "" }]
  },
  data() {
    return {
      carousel: false,
      imageArray: [],
      carouselItems: null,
      activeCarouselItem: 0
    };
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData() {
      const result = await axios.get("/get-images");
      this.imageArray = result.data.imageArray;
      this.carouselItems = this.getCarouselItems(this.imageArray);
    },
    getCarouselItems: function(input) {
      const stack = [...input];
      const res = [];
      while (stack.length) {
        // pop value from stack
        const next = stack.pop();
        if (Array.isArray(next)) {
          // push back array items, won't modify the original input
          stack.push(...next);
        } else {
          res.push(next);
        }
      }
      // reverse to restore input order
      return res.reverse();
    },
    getRatio: function(width, height, block) {
      let blockSize = block.length === 1;
      let ratio = blockSize ? 1 : width / height;
      return {
        "-webkit-box-flex": ratio,
        "-moz-box-flex": ratio,
        "-webkit-flex": ratio,
        "-ms-flex": ratio,
        flex: ratio
      };
    },
    getSrc: function(targetImage) {
      return `https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/${targetImage}.jpg`;
    },
    getSrcSet: function(targetImage) {
      return `https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_360/${targetImage}.jpg 320w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_520/${targetImage}.jpg 480w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_800/${targetImage}.jpg 800w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/${targetImage}.jpg 1000w`;
    },
    initCarousel: function(id) {
      const idMatch = element => element.id === id;
      this.activeCarouselItem = this.carouselItems.findIndex(idMatch);
      this.carousel = true;
      window.addEventListener("keyup", this.keyNavigate, false);
    },
    destroyCarousel: function() {
      this.carousel = false;
      window.removeEventListener("keyup", this.keyNavigate, false);
    },
    carouselNext: function() {
      this.activeCarouselItem++;

      if (this.activeCarouselItem > this.carouselItems.length - 1) {
        this.activeCarouselItem = 0;
      }
    },
    carouselPrevious: function() {
      this.activeCarouselItem--;

      if (this.activeCarouselItem < 0) {
        this.activeCarouselItem = this.carouselItems.length - 1;
      }
    },
    scrollToTop: function() {
      window.scrollTo(0, 0);
    },
    keyNavigate: function(event) {
      if (event.keyCode === 39) {
        this.carouselNext();
      }

      if (event.keyCode === 37) {
        this.carouselPrevious();
      }

      if (event.keyCode === 27) {
        this.destroyCarousel();
      }
    }
  }
};
</script>

<style lang="scss">
html.overflow {
  overflow: auto;
  overflow: overlay;

  &.carousel-open {
    overflow-y: hidden;
  }
}

.gallery {
  padding: 0;

  @media only screen and (min-width: 768px) {
    display: flex;

    &-item {
      padding: 0.25rem;
      font-size: 0;
    }
  }
}

.scroll-to-top {
  position: fixed;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent rgb(161, 161, 161) transparent;
  z-index: 5;
  cursor: pointer;
  bottom: 3.25rem;
  transform: translateY(-50%);
  right: 2rem;

  &:hover {
    border-color: transparent transparent rgba(0, 0, 0, 0.65) transparent;
  }
}

.carousel {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  height: 100%;
  padding: 0;
  text-align: center;

  &-previous,
  &-next {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    z-index: 10;
    margin: 1rem;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
  }

  &-previous {
    border-right: 20px solid rgba(255, 255, 255, 0.8);
    left: 0;

    &:hover {
      border-right: 20px solid rgba(255, 255, 255, 1);
    }
  }

  &-next {
    border-left: 20px solid rgba(255, 255, 255, 0.8);
    right: 0;

    &:hover {
      border-left: 20px solid rgba(255, 255, 255, 1);
    }
  }

  figure {
    position: absolute;
    top: 0;
    height: calc(100% - 5rem);
    width: 100%;
    display: flex;
  }

  img {
    width: auto;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    margin: auto;
    max-width: 100%;
    object-fit: scale-down;
  }

  figcaption {
    position: absolute;
    bottom: -2.5rem;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
