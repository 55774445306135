<template>
  <main class="admin-section">
    <section class="admin-panel">
      <button :disabled="editMode" @click="editMode = true">edit</button>
      <button :disabled="!editMode" @click="editMode = false">view</button>
      <button :disabled="!changed" @click="updateDraftImages">update images</button>
      <button @click="publishImages">publish</button>
      <button @click="logout">logout</button>
    </section>
    <section
      :key="blockIndex"
      class="gallery"
      v-for="(imageBlock, blockIndex) in imageArray"
    >
      <div class="item-edit" v-if="editMode">
        <button @click="addItem(imageArray, blockIndex)">add item</button>
        <button @click="addRow(imageArray, blockIndex)">add row</button>
        <button @click="removeCurrentItem(imageArray, blockIndex)">delete row</button>
      </div>
      <figure
        :key="image.id"
        :style="getRatio(image.width, image.height, imageBlock)"
        class="gallery-item"
        v-for="(image, imageIndex) in imageBlock"
      >
        <div class="item-edit" v-if="editMode">
          <button @click="editImage(blockIndex, imageIndex)">edit</button>
          <button @click="removeCurrentItem(imageBlock, index)">remove</button>
          <div class="image-edit" v-if="editedImage && editedImage.id === image.id">
            <label for="src" class="label">URL</label>
            <input type="text" v-model="editedImage.src" id="src">
            <label for="alt" class="label">ALT</label>
            <input type="text" v-model="editedImage.alt" id="alt">
            <label for="width" class="label">WIDTH</label>
            <input type="text" v-model="editedImage.width" id="width">
            <label for="height" class="label">HEIGHT</label>
            <input type="text" v-model="editedImage.height" id="height">
            <button @click="saveImage(blockIndex, imageIndex)">save</button>
            <button @click="editedImage = null">cancel</button>
          </div>
        </div>
        <img
          :alt="image.alt"
          :data-src="getSrc(image.src)"
          :data-srcset="getSrcSet(image.src)"
          :class="editedImage && editedImage.id === image.id ? 'edit' : ''"
          class="lazyload"
          sizes="(max-width: 320px) 280px,
               (max-width: 480px) 440px,
               (max-width: 800px) 768px,
               1000px"
          v-if="image.src !== 'placeholder'"
        />
        <img
          alt="placeholder"
          src="https://glowvarietyshow.com/wp-content/uploads/2017/03/placeholder-image.jpg"
          v-else
        />
      </figure>
    </section>
  </main>
</template>

<script>
  import axios from '@/services/axios';
  import "lazysizes";

  export default {
    middleware: 'auth',
    head() {
      return {
        htmlAttrs: {
          class: "overflow"
        },
        title: "Admin | Sora",
        meta: [{hid: "description", name: "description", content: ""}],
      };
    },
    data() {
      return {
        imageArray: [],
        editedImage: null,
        id: null,
        editMode: false,
        changed: false,
      };
    },
    created() {
      this.asyncData();
    },
    methods: {
      async asyncData() {
        const result = await axios.get('/get-draft-images');
        this.id = result.data.id;
        this.imageArray = result.data.imageArray;
      },
      getRatio: function (width, height, block) {
        let blockSize = block.length === 1;
        let ratio = blockSize ? 1 : width / height;
        return {
          "-webkit-box-flex": ratio,
          "-moz-box-flex": ratio,
          "-webkit-flex": ratio,
          "-ms-flex": ratio,
          flex: ratio
        };
      },
      getSrc: function (targetImage) {
        return `https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/${targetImage}.jpg`;
      },
      getSrcSet: function (targetImage) {
        return `https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_360/${targetImage}.jpg 320w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_520/${targetImage}.jpg 480w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_800/${targetImage}.jpg 800w,
              https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_1980/${targetImage}.jpg 1000w`;
      },
      removeCurrentItem: function (block, index) {
        this.changed = true;
        block.splice(index, 1)
      },
      addRow: function (block, index) {
        this.changed = true;
        block.splice(index, 0, []);
      },
      addItem: function (block, index) {
        this.changed = true;
        let placeholderRow = {
          "id": "ax8ifu",
          "src": "v1578673283/raw/paris-from-top_ax8ifu",
          "alt": "This is the alt ",
          "width": 1333,
          "height": 1333
        };
        block[index].unshift(placeholderRow);
      },
      editImage: function (blockIndex, imageIndex) {
        this.changed = true;
        this.editedImage = {...this.imageArray[blockIndex][imageIndex]};
      },
      saveImage: function (blockIndex, imageIndex) {
        let editedImageArray = this.editedImage.src.split("_");
        this.editedImage.id = editedImageArray[editedImageArray.length - 1];
        this.imageArray[blockIndex][imageIndex] = this.editedImage;
        this.editedImage = null;
      },
      logout: function () {
        sessionStorage.removeItem(process.env.VUE_APP_JWT_STORAGE);
        this.$router.push('work');
      },
      async publishImages() {
        await this.updateDraftImages();
        const data = {
          "id": this.id,
          "imageArray": this.imageArray
        };
        try {
          await axios.put('/update-images', data)
        } catch (error) {
          console.log(error);
        }
      },
      async updateDraftImages() {
        const data = {
          "_id": this.id,
          "imageArray": this.imageArray
        };
        try {
          await axios.put('/update-draft-images', data)
        } catch (error) {
          console.log(error);
        }

      }
    }
  };
</script>

<style lang="scss" scoped>
  .admin-section {
    font-size: 12px;
  }

  .admin-panel {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0.25rem;
    background-color: white;
    width: 100%;
    margin-left: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .item-edit {
    z-index: 1;
  }

  .image-edit {
    label {
      display: block;
      width: calc(100% - 1.75rem);
      margin: 0.5rem 0;
      color: white;
      font-size: 12px
    }

    input {
      display: block;
      width: calc(100% - 1.75rem);
      margin: 0.5rem 0;
      padding: 0.5rem;
    }
  }

  .gallery {
    padding: 0;

    img.edit {
      filter: brightness(0.25);
    }

    & > .item-edit {
      flex-basis: 100%;
      padding: 0.25rem;
    }

    @media only screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;

      &-item {
        position: relative;
        padding: 0.25rem;
        font-size: 0;

        .item-edit {
          position: absolute;
          width: calc(100% - 1rem);
          padding: 0.5rem;;
        }
      }
    }
  }
</style>
