import Index from '@/route-components/Index';
import Work from '@/route-components/Work';
import About from '@/route-components/About';
import Admin from '@/route-components/Admin';
import Contact from '@/route-components/Contact';
import Login from '@/route-components/Login';
import axios from '@/services/axios';

export const routes = [
  {path: '/', component: Index},
  {path: '/work', component: Work},
  {path: '/about', component: About},
  {path: '/contact', component: Contact},
  {path: '/login', component: Login},
  {
    path: '/admin', component: Admin, beforeEnter: (to, from, next) => {
      if (axios.defaults.headers.authorization) next();
      const currJWT = sessionStorage.getItem(process.env.VUE_APP_JWT_STORAGE);
      if (!currJWT) next('/login');
      axios.defaults.headers.authorization = `Bearer ${currJWT}`;
      next();
    }
  },
];
