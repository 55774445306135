<template>
  <main>
    <nav aria-label="main navigation" class="navbar is-flex" role="navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">SORA</router-link>
      </div>
      <div class="navbar-menu" v-if="$route.path !== '/'">
        <router-link class="navbar-item" to="/work">work</router-link>
        <router-link class="navbar-item" to="/about">about</router-link>
        <router-link class="navbar-item" to="/contact">contact</router-link>
      </div>
    </nav>
    <router-view></router-view>
  </main>
</template>

<script>
import "lazysizes";

export default {
  name: "App",
};
</script>

<style lang="scss">
@import "~reset.css";
@import "assets/font.scss";

html {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  line-height: 1.8;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  background-color: #e8e7e3;
  color: #666;
  overflow: auto;
  height: auto;
  font-weight: 100;
  scroll-behavior: smooth;
}

body {
  transition: padding 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-size: 0.9375em;
  letter-spacing: 0.09375rem;
  line-height: 1.8;
  height: auto;
  padding: 1.25rem 3.25rem;
  color: #a1a1a1;

  &.white {
    color: #fff;

    .navbar-brand {
      color: black;
    }

    @media only screen and (min-width: 1024px) {
      padding: 7.75rem 50% 3.75rem 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 3.75rem 17.5rem;
  }
}

p {
  margin-bottom: 1rem;
}

figure {
  img {
    width: 100%;
  }
}

.main {
  transition: max-width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  @media only screen and (min-width: 768px) {
    transition: max-width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    max-width: 36rem;
    width: 50%;
  }
}

.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.navbar {
  text-align: center;

  &-brand {
    font-size: 4rem;
    font-family: "Brandon", "Open Sans", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    line-height: 1;

    .navbar-item.nuxt-link-exact-active {
      color: #fff;
    }

    .navbar-item:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &-menu {
    padding-bottom: 2rem;
    color: inherit;
    line-height: 2em;
  }

  &-item {
    padding: 0 1rem;
    display: inline-block;
    color: inherit;
    font-size: inherit;
    text-decoration: none;

    &:hover,
    &.nuxt-link-exact-active {
      color: #000;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
</style>
