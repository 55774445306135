<template>
  <main class="main">
    <section>
      <h1>Light and shadow. Object and space. Building and landscape.</h1>
      <router-link class="main-button" tag="button" to="/work">
        Enter
      </router-link>
    </section>
    <div class="cover" name="fade" tag="section">
      <img
        class="desktop-cover"
        :alt="sora"
        :src="`https://res.cloudinary.com/sora-images-com/image/upload/v1605115163/raw/SoraDesktopBG_rt1jds.jpg`"
        :srcset="`https://res.cloudinary.com/sora-images-com/image/upload/c_fill,f_auto,q_auto:best,w_1200/v1605115163/raw/SoraDesktopBG_rt1jds.jpg 1000w,
                  https://res.cloudinary.com/sora-images-com/image/upload/f_auto,q_auto:best,w_2400/v1605115163/raw/SoraDesktopBG_rt1jds.jpg 1200w`"
        sizes="(max-width: 480px) 440px,
             (max-width: 800px) 768px,
             (max-width: 1200px) 1000px
             1200px"
      />
      <img
        class="mobile-cover"
        :alt="sora"
        :src="`https://res.cloudinary.com/sora-images-com/image/upload/v1605115163/raw/SoraIphoneBG_jlhzym.jpg`"
        :srcset="`https://res.cloudinary.com/sora-images-com/image/upload/c_fill,f_auto,q_auto:best,h_812,w_480/v1605115163/raw/SoraIphoneBG_jlhzym.jpg 480w,
                  https://res.cloudinary.com/sora-images-com/image/upload/c_fill,f_auto,q_auto:best,h_960,w_820/v1605115163/raw/SoraIphoneBG_jlhzym.jpg 800w,`"
        sizes="(max-width: 480px) 440px,
             (max-width: 800px) 768px,
             (max-width: 1200px) 1000px
             1200px"
      />
    </div>
  </main>
</template>

<script>
export default {
  name: "index",
  metaInfo: {
    bodyAttrs: {
      class: "white",
    },
    title: "Sora",
    meta: [{ hid: "description", name: "description", content: "" }],
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/favicon.ico",
      },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com/",
        crossorigin: true,
      },
      {
        rel: "stylesheet",
        href:
          "https://fonts.googleapis.com/css?family=Raleway:100&display=swap",
      },
    ],
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
main {
  color: white;
  max-width: 100%;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    color: black;
  }
}

h1 {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 100;
  font-size: 1rem;
  letter-spacing: 0;
  padding-bottom: 2em;
  line-height: 2em;
  color: white;

  @media only screen and (min-width: 1024px) {
    color: black;
  }
}

.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  z-index: -1;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .desktop-cover {
    display: none;

    @media only screen and (min-width: 1024px) {
      display: block;
    }
  }

  .mobile-cover {
    display: block;

    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.main-button {
  appearance: none;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: inherit;
  border: 2px solid white;
  font-size: 0.875rem;
  letter-spacing: 0.175rem;
  padding: 1em 1.35em;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  @media only screen and (min-width: 1024px) {
    border: 2px solid black;
  }

  &:hover {
    background-color: #fff;
    color: #a1a1a1;
    border: 2px solid #a1a1a1;
  }
}
</style>

