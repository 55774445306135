<template>
    <main class="main">
        <section>
            <form @submit.prevent="authenticate">
                <input v-model="login.username" type="text"/>
                <input v-model="login.password" type="password"/>
                <p v-if="response">{{ response }}</p>
                <input type="submit"/>
            </form>
        </section>
    </main>
</template>

<script>
  import axios from '@/services/axios';

  export default {
    metaInfo: {
      title: 'Login'
    },
    data() {
      return {
        login: {
          username: null,
          password: null
        },
        response: null
            }
        },
        methods: {
            authenticate: async function () {
              try {
                const result = await axios.post('/auth/login', {
                    username: this.login.username,
                    password: this.login.password
                });
                sessionStorage.setItem(process.env.VUE_APP_JWT_STORAGE, result.data.token);
                this.$router.push('admin');
              } catch(error) {
                if (error.response.status === 401) {
                  this.response = 'Invalid username or password!';
                } else {
                  this.response = 'Error during login!';
                }
              }
            }
        }
    };
</script>

<style scoped>

</style>
